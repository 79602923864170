package ru.totalguide.ui.icons

val Icons.Solid.ThumbsUp
    get() = solidThumbsUp

private val solidThumbsUp = Icons.createSvgIcon(
    "M7.25 11H4.75C4.3125 11 4 11.3438 4 11.75V19.25C4 19.6875 4.3125 20 4.75 20H7.25C7.65625 20 8 19.6875 8 19.25V11.75C8 11.3438 7.65625 11 7.25 11ZM6 18.75C5.5625 18.75 5.25 18.4375 5.25 18C5.25 17.5938 5.5625 17.25 6 17.25C6.40625 17.25 6.75 17.5938 6.75 18C6.75 18.4375 6.40625 18.75 6 18.75ZM16 6.5625C16 4.25 14.5 4 13.75 4C13.0938 4 12.8125 5.25 12.6875 5.8125C12.5 6.5 12.3438 7.1875 11.875 7.65625C10.875 8.6875 10.3438 9.96875 9.09375 11.1875C9.03125 11.2812 9 11.375 9 11.4688V18.1562C9 18.3438 9.15625 18.5 9.34375 18.5312C9.84375 18.5312 10.5 18.8125 11 19.0312C12 19.4688 13.2188 20 14.7188 20H14.8125C16.1562 20 17.75 20 18.375 19.0938C18.6562 18.7188 18.7188 18.25 18.5625 17.6875C19.0938 17.1562 19.3438 16.1562 19.0938 15.3438C19.625 14.625 19.6875 13.5938 19.375 12.875C19.75 12.5 20 11.9062 19.9688 11.3438C19.9688 10.375 19.1562 9.5 18.125 9.5H14.9375C15.1875 8.625 16 7.875 16 6.5625Z"
)

val Icons.Regular.ThumbsUp
    get() = regularThumbsUp

private val regularThumbsUp = Icons.createSvgIcon(
    "M18.5625 12.9688C18.8438 12.5 19 12 19 11.4062C19 10.0312 17.8125 8.75 16.3125 8.75H15.1562C15.3125 8.34375 15.4375 7.875 15.4375 7.28125C15.4375 5 14.25 4 12.4688 4C10.5312 4 10.6562 6.96875 10.2188 7.40625C9.5 8.125 8.65625 9.5 8.0625 10H5C4.4375 10 4 10.4688 4 11V18.5C4 19.0625 4.4375 19.5 5 19.5H7C7.4375 19.5 7.84375 19.1875 7.9375 18.7812C9.34375 18.8125 10.3125 20 13.5 20C13.75 20 14 20 14.2188 20C16.625 20 17.6875 18.7812 17.7188 17.0312C18.1562 16.4688 18.375 15.6875 18.2812 14.9375C18.5938 14.375 18.6875 13.6875 18.5625 12.9688ZM16.625 14.6562C17.0312 15.3125 16.6562 16.1875 16.1875 16.4688C16.4375 17.9688 15.625 18.5 14.5312 18.5H13.3438C11.125 18.5 9.65625 17.3438 8 17.3438V11.5H8.3125C9.21875 11.5 10.4375 9.3125 11.2812 8.46875C12.1562 7.59375 11.875 6.09375 12.4688 5.5C13.9375 5.5 13.9375 6.53125 13.9375 7.28125C13.9375 8.5 13.0625 9.0625 13.0625 10.25H16.3125C16.9688 10.25 17.4688 10.8438 17.5 11.4375C17.5 12 17.0938 12.5938 16.7812 12.5938C17.2188 13.0625 17.3125 14.0312 16.625 14.6562ZM6.75 17.5C6.75 17.9375 6.40625 18.25 6 18.25C5.5625 18.25 5.25 17.9375 5.25 17.5C5.25 17.0938 5.5625 16.75 6 16.75C6.40625 16.75 6.75 17.0938 6.75 17.5Z"
)

val Icons.Light.ThumbsUp
    get() = lightThumbsUp

private val lightThumbsUp = Icons.createSvgIcon(
    "M19.5 12.9375C19.8125 12.5312 20 12 20 11.375C19.9688 10.1875 18.9688 9.09375 17.7188 9.09375H15.5312C15.7812 8.5625 16.1562 7.875 16.1562 6.9375C16.1562 5.09375 15.4375 4 13.5625 4C12.625 4 12.2812 5.1875 12.125 6.125C12 6.65625 11.9062 7.1875 11.5938 7.5C10.8438 8.21875 9.75 10 9.0625 10.375C9 10.4062 8.90625 10.4375 8.8125 10.4375C8.625 10.1875 8.3125 10 8 10H5C4.4375 10 4 10.4688 4 11V19C4 19.5625 4.4375 20 5 20H8C8.53125 20 9 19.5625 9 19V18.75C10 18.75 12.125 20.0312 14.5312 20C14.6875 20 15.7188 20.0312 15.8125 20C17.6562 20 18.6875 18.9062 18.625 17.2188C19.0938 16.6562 19.3438 15.8438 19.2188 15.125C19.5938 14.5 19.6875 13.6562 19.5 12.9375ZM5 19V11H8V19H5ZM18.25 12.6562C18.75 13 18.75 14.5 18.0625 14.875C18.4688 15.5625 18.0938 16.5312 17.5938 16.8125C17.8438 18.4375 17 19 15.8125 19C15.6875 19.0312 14.625 19 14.5312 19C12.25 19 10.3125 17.75 9 17.75V11.375C10.1562 11.375 11.25 9.25 12.3125 8.1875C13.25 7.25 12.9375 5.65625 13.5625 5C15.1562 5 15.1562 6.125 15.1562 6.9375C15.1562 8.25 14.2188 8.84375 14.2188 10.0938H17.7188C18.4375 10.0938 18.9688 10.75 19 11.375C19 12 18.75 12.5 18.25 12.6562ZM7.25 17.5C7.25 17.0938 6.90625 16.75 6.5 16.75C6.0625 16.75 5.75 17.0938 5.75 17.5C5.75 17.9375 6.0625 18.25 6.5 18.25C6.90625 18.25 7.25 17.9375 7.25 17.5Z"
)