package ru.totalguide.app.usecases.sightlist

import ru.totalguide.ui.ViewableImage

sealed interface SightListViewState

data object EmptySightListViewState : SightListViewState

data object LoadSightListViewState : SightListViewState {
    const val message: String = "Загрузка данных..."
}

data class DisplaySightListViewState internal constructor(
    val sights: List<ViewableSightPreview>,
    val hasMoreItems: Boolean,
    val isNextPageLoading: Boolean,
) : SightListViewState {

    data class ViewableSightPreview(
        val id: String,
        val name: String,
        val slug: String,
        val detailsUrl: String,
        val description: String,
        val covers: List<ViewableImage>,
        val isAddedToRoute: Boolean,
        val likesCount: String,
        val dislikesCount: String,
        val rating: String,
        val showOnMapUrl: String,
        val routeUrl: String,
        val callTaxiUrl: String,
    )
}
