package ru.totalguide.ui

import emotion.react.css
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.rem

fun ChildrenBuilder.placeholder(onClick: OnClickHandler = {}) = PlaceholderComponent {
    this.onClick = onClick
}

@Suppress("PrivatePropertyName")
private val PlaceholderComponent = FC<PlaceholderComponentProps>("Placeholder") {props ->
    val theme = useTGTheme()
    val (textStyle) = useState(theme.typography.paragraph)
    div {
        css {
            applyTextStyle(textStyle)
            width = 100.pct
            height = 232.rem
            backgroundColor = Color("#999999")
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            color = Color("#ffffff")
        }
        onClick = { props.onClick() }
        +"Нет изображения"
    }
}

private external interface PlaceholderComponentProps: Props {
    var onClick: OnClickHandler
}