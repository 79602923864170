package ru.totalguide.app.usecases.sightdetails

import ru.totalguide.ui.ViewableImage

sealed interface SightDetailsViewState

data object EmptySightDetailsViewState : SightDetailsViewState

data class DisplaySightDetailsViewState(
    val details: ViewableSightDetails
) : SightDetailsViewState {

    data class ViewableSightDetails(
        val id: String,
        val title: String,
        val description: String,
        val covers: List<ViewableImage>,
        val theses: List<String>,
        val isAddedToRoute: Boolean,
        val likesCount: String,
        val dislikesCount: String,
        val rating: String,
        val showOnMapUrl: String,
        val routeUrl: String,
        val callTaxiUrl: String,
    )
}

data object LoadingSightDetailsViewState : SightDetailsViewState

data object NotFoundViewState : SightDetailsViewState