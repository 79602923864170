package ru.totalguide.app.usecases.sightlist

import ru.totalguide.core.entities.Image
import ru.totalguide.core.entities.Location

data class SightPreview(
    val id: String,
    val name: String,
    val slug: String,
    val detailsUrl: String,
    val description: String,
    val covers: List<Image>,
    val likesCount: Long,
    val dislikesCount: Long,
    val rating: Long,
    val location: Location,
)