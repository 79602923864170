package ru.totalguide.ui.text


import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML
import ru.totalguide.ui.append
import ru.totalguide.ui.applyTextStyle
import ru.totalguide.ui.style
import ru.totalguide.ui.useTGTheme
import web.cssom.ClassName

fun ChildrenBuilder.h1(text: String, style: ClassName? = null) = H1Component {
    this.text = text
    this.style = style
}

@Suppress("PrivatePropertyName")
private val H1Component = FC<H1ComponentProps>("H1") { props ->
    val style = style {
        applyTextStyle(useTGTheme().typography.heading1)
    }.append(props.style)

    ReactHTML.h1 {
        className = style
        +props.text
    }
}

private external interface H1ComponentProps : Props {
    var text: String
    var style: ClassName?
}