package ru.totalguide.app.commands

import ru.totalguide.core.repositories.RouteRepository

class RemoveSightFromRouteCommand(
    private val repository: RouteRepository, private val sightId: String) {
    fun execute() {
        val currentRoute = repository.currentRoute.value
        val newRoute = currentRoute.copy(sights = currentRoute.sights - sightId)

        repository.save(newRoute)
    }

}
