package ru.totalguide.core.data

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import ru.totalguide.core.entities.Route
import ru.totalguide.core.repositories.RouteRepository

class InMemoryRouteRepository(private var sights: List<String> = listOf()) : RouteRepository {
    private val _currentRoute = MutableStateFlow(Route(sights = sights))
    override val currentRoute: StateFlow<Route> = _currentRoute.asStateFlow()

    override fun save(route: Route) {
        sights = route.sights
        _currentRoute.tryEmit(Route(sights = sights))
    }

    companion object{
        val singleton = InMemoryRouteRepository()
    }
}
