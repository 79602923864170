package ru.totalguide.app.usecases.sightdetails

import ru.totalguide.core.entities.Image
import ru.totalguide.core.entities.Location

data class SightDetails(
    val id: String,
    val name: String,
    val description: String,
    val covers: List<Image>,
    val theses: List<String>,
    val isAddedToRoute: Boolean,
    val likesCount: Long,
    val dislikesCount: Long,
    val rating: Long,
    val location: Location,
)