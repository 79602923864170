package ru.totalguide.ui.text

import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.p
import ru.totalguide.ui.append
import ru.totalguide.ui.applyTextStyle
import ru.totalguide.ui.style
import ru.totalguide.ui.useTGTheme
import web.cssom.ClassName

fun ChildrenBuilder.p(text: String, style: ClassName? = null) = PComponent {
    this.text = text
    this.style = style
}

@Suppress("PrivatePropertyName")
private val PComponent = FC<PComponentProps>("P") { props ->
    val style = style {
        applyTextStyle(useTGTheme().typography.paragraph)
    }.append(props.style)

    p {
        className = style

        +props.text
    }
}

private external interface PComponentProps : Props {
    var text: String
    var style: ClassName?
}