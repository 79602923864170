package ru.totalguide.core.data

import ru.totalguide.core.entities.Sight
import ru.totalguide.core.repositories.Page
import ru.totalguide.core.repositories.SightRepository
import kotlin.math.min

class InMemorySightRepository(

    private val sights: List<Sight>

) : SightRepository {

    override suspend fun getAll(): List<Sight> {
        return sights.map(Sight::copy)
    }

    override fun get(sightId: String): Sight = sights.find { sight -> sight.id == sightId }!!.copy()
    override suspend fun getBySlug(slug: String): Sight  = sights.find { sight -> sight.slug == slug }!!.copy()
    override suspend fun getPage(page: Int, itemsPerPage: Int): Page<Sight> {
        val startIndex = (page - 1) * itemsPerPage
        val endIndex = min(startIndex + itemsPerPage, sights.size)
        val items = sights.subList(startIndex, endIndex).map(Sight::copy)
        val hasMoreItems = endIndex < sights.size
        return Page(items, hasMoreItems)
    }
}