package ru.totalguide.ui.icons

val Icons.Solid.ThumbsDown
    get() = solidThumbsDown

private val solidThumbsDown = Icons.createSvgIcon(
    "M4 5.75V13.25C4 13.6875 4.3125 14 4.75 14H7.25C7.65625 14 8 13.6875 8 13.25V5.75C8 5.34375 7.65625 5 7.25 5H4.75C4.3125 5 4 5.34375 4 5.75ZM5.25 12C5.25 11.5938 5.5625 11.25 6 11.25C6.40625 11.25 6.75 11.5938 6.75 12C6.75 12.4375 6.40625 12.75 6 12.75C5.5625 12.75 5.25 12.4375 5.25 12ZM13.75 20C14.5 20 16 19.75 16 17.4688C16 16.1562 15.1875 15.4062 14.9375 14.5H18.125C19.1562 14.5 19.9688 13.6562 20 12.6875C20 12.125 19.75 11.5312 19.375 11.1562C19.6875 10.4375 19.625 9.40625 19.0938 8.6875C19.3438 7.875 19.0938 6.875 18.5625 6.34375C18.7188 5.78125 18.6562 5.3125 18.375 4.9375C17.75 4.03125 16.1562 4 14.8125 4H14.7188C13.2188 4.03125 12 4.5625 11 5C10.5 5.21875 9.84375 5.5 9.34375 5.5C9.15625 5.53125 9 5.6875 9 5.875V12.5625C9 12.6875 9.03125 12.7812 9.09375 12.8438C10.3438 14.0625 10.875 15.3438 11.875 16.375C12.3438 16.8438 12.5 17.5312 12.6875 18.2188C12.8125 18.7812 13.0938 20 13.75 20Z"
)

val Icons.Regular.ThumbsDown
    get() = regularThumbsDown

private val regularThumbsDown = Icons.createSvgIcon(
    "M18.5625 11.0625C18.6875 10.3438 18.5938 9.65625 18.2812 9.09375C18.375 8.34375 18.1562 7.5625 17.7188 7C17.6875 5.25 16.625 4 14.2188 4C14 4 13.75 4.03125 13.5 4.03125C10.2812 4.03125 9.25 5.25 8 5.25H7.65625C7.46875 5.125 7.25 5 7 5H5C4.4375 5 4 5.46875 4 6V13.5C4 14.0625 4.4375 14.5 5 14.5H7C7.34375 14.5 7.6875 14.3125 7.84375 14H8.0625C8.65625 14.5312 9.5 15.9062 10.2188 16.625C10.6562 17.0625 10.5312 20 12.4688 20C14.25 20 15.4375 19.0312 15.4375 16.75C15.4375 16.1562 15.3125 15.6875 15.1562 15.2812H16.3125C17.8125 15.2812 19 14 19 12.625C19 12 18.8438 11.5312 18.5625 11.0625ZM6 13.25C5.5625 13.25 5.25 12.9375 5.25 12.5C5.25 12.0938 5.5625 11.75 6 11.75C6.40625 11.75 6.75 12.0938 6.75 12.5C6.75 12.9375 6.40625 13.25 6 13.25ZM16.3125 13.7812H13.0625C13.0625 14.9688 13.9375 15.5312 13.9375 16.75C13.9375 17.5 13.9375 18.5 12.4688 18.5C11.875 17.9375 12.1562 16.4375 11.2812 15.5625C10.4375 14.7188 9.21875 12.5 8.3125 12.5H8V6.6875C9.65625 6.6875 11.125 5.53125 13.3438 5.53125H14.5312C15.625 5.53125 16.4375 6.0625 16.1875 7.5625C16.6562 7.84375 17.0312 8.71875 16.625 9.375C17.3125 10 17.2188 10.9688 16.7812 11.4375C17.0938 11.4375 17.5 12.0312 17.5 12.5938C17.4688 13.1875 16.9688 13.7812 16.3125 13.7812Z"
)

val Icons.Light.ThumbsDown
    get() = lightThumbsDown

private val lightThumbsDown = Icons.createSvgIcon(
    "M19.5 11.0937C19.6875 10.375 19.5938 9.53122 19.2188 8.90622C19.3438 8.18747 19.0938 7.37497 18.625 6.81247C18.6875 5.12497 17.6562 3.99997 15.8125 4.03122C15.7188 3.99997 14.6875 4.03122 14.5312 4.03122C12.125 3.99997 10.0938 5.24997 9 5.24997H8.65625C8.46875 5.12497 8.25 4.99997 8 4.99997H5C4.4375 4.99997 4 5.46872 4 5.99997V14C4 14.5625 4.4375 15 5 15H8C8.53125 15 9 14.5625 9 14V13.625C9 13.625 9.03125 13.6562 9.0625 13.6562C9.75 14 10.8438 15.8125 11.5938 16.5312C11.9062 16.8437 12 17.375 12.125 17.9062C12.2812 18.8437 12.625 20 13.5625 20C15.4375 20 16.1562 18.9375 16.1562 17.0937C16.1562 16.1562 15.7812 15.4687 15.5312 14.9375H17.7188C18.9688 14.9375 20 13.8437 20 12.6562C20 12 19.8125 11.5 19.5 11.0937ZM8 14H5V5.99997H8V14ZM17.7188 13.9375H14.2188C14.2188 15.1875 15.1562 15.7812 15.1562 17.0937C15.1562 17.9062 15.1562 19.0312 13.5625 19.0312C12.9375 18.375 13.25 16.7812 12.3125 15.8437C11.4062 14.9375 10.0625 12.6562 9.125 12.6562H9V6.28122H9.125C10.2188 6.28122 12.3125 5.03122 14.5312 5.03122C14.625 5.03122 15.6875 4.99997 15.8125 5.03122C17 5.03122 17.8438 5.59372 17.5938 7.21872C18.0938 7.49997 18.4688 8.46872 18.0625 9.15622C18.75 9.49997 18.75 11 18.25 11.375C18.75 11.5 19 12.0312 19 12.6562C18.9688 13.2812 18.4375 13.9375 17.7188 13.9375ZM7.25 12.5C7.25 12.0937 6.90625 11.75 6.5 11.75C6.0625 11.75 5.75 12.0937 5.75 12.5C5.75 12.9375 6.0625 13.25 6.5 13.25C6.90625 13.25 7.25 12.9375 7.25 12.5Z"
)