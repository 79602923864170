package ru.totalguide.app.usecases.sightlist

sealed class SightListEvent {
    data class AddSightToRoute(val sightId: String): SightListEvent()
    data object LoadNextPage: SightListEvent()
    data object RefreshViewState: SightListEvent()
    data class RemoveSightFromRoute(val sightId: String): SightListEvent()
    data object ShowRouteEditor: SightListEvent()
    data class ShowSightDetails(val sightSlug: String): SightListEvent()
}
