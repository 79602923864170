package ru.totalguide.ui

import emotion.css.ClassName
import emotion.react.css
import react.ChildrenBuilder
import react.FC
import react.Props
import react.PropsWithClassName
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.svg
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FontStyle
import web.cssom.FontWeight
import web.cssom.None
import web.cssom.rem
import web.cssom.string

/**
 * Прозрачная кнопка.
 *
 * @param text Текст.
 * @param icon Значок.
 * @param isActive Признак активности.
 * @param onClick Обработчик нажатия на кнопку.
 */
fun ChildrenBuilder.likeButton(
    text: String,
    icon: FC<Props>,
    isActive: Boolean = false,
    onClick: () -> Unit,
) = LikeButton {
    this.onClick = onClick
    this.text = text
    this.icon = icon
    this.isActive = isActive

//    val outlineColor = colors.hoverForeground.alpha(HOVER_COLOR_ALPHA)
//    css {
//        focus {
//            outline = Outline(4.rem, LineStyle.solid, outlineColor)
//        }
//    }
}
private external interface LikeButtonProps : PropsWithClassName {
    var isActive: Boolean
    var icon: FC<Props>
    var onClick: () -> Unit
    var text: String
}

@Suppress("PrivatePropertyName")
private val LikeButton = FC<LikeButtonProps>("LikeButton") { props ->
    val theme = useTGTheme()
    val overridableStyles = ClassName {
        border = None.none
        outline = None.none
    }

    ReactHTML.button {
        css(overridableStyles, props.className) {
            display = Display.flex
//            alignItems = AlignItems.flexStart
            columnGap = 4.rem
            backgroundColor = Color("#FFFFFF")
            color = theme.colorScheme.textBasic

            focus {
//                textShadow = TextShadow(0.rem, 4.rem, 8.rem, 0.rem, theme.colorScheme.primary)
            }
            hover {
                color = Color("#000000")
                cursor = Cursor.pointer
            }
            active {
                outline = None.none
                color = theme.colorScheme.primary
            }
            disabled {
                boxShadow = None.none
            }

            svg {
                fontSize = 20.rem
            }
        }

        onClick = { event ->
            event.stopPropagation()
            props.onClick()
        }
        disabled = props.isActive
        props.icon()

        span {
            css {
                fontFamily = string("Golos Text")
                fontSize = 16.rem
                fontStyle = FontStyle.normal
                lineHeight = 24.rem
                fontWeight = "400".unsafeCast<FontWeight>()
            }
            +props.text
        }
    }
}