package ru.totalguide.core.entities

data class Sight(
    val id: String = "",
    val name: String,
    val slug: String,
    val description: String,
    val covers: List<Image>,
    val theses: List<Thesis>,
    val rating: Rating,
    val location: Location,
)